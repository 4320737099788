
import { Component, Vue } from 'vue-property-decorator'
import { mdiAccount, mdiAt, mdiChairRolling, mdiBookAlertOutline, mdiMenuDown } from '@mdi/js'
import Contact from '../components/Contact.vue'
import ContactsTable from '../components/ContactsTable.vue'
import { debounce } from 'lodash'

@Component({ components: { Contact, ContactsTable } })
export default class Dashboard extends Vue {
  loading = false
  snackbarSuccess = false
  snackbarError = false

  contactsDialog = false
  commentDialog = false
  tagsDialog = false
  advancedFiltersDialog = false

  domain = ''
  newTagText = ''
  searchTags = ''
  selectedTags = []

  advancedFilters = {
    minTotalOrders: undefined,
    minWeeklyOrders: undefined,
    minRevenue: undefined,
    maxTotalOrders: undefined,
    maxWeeklyOrders: undefined,
    maxRevenue: undefined
  }

  status = ''

  svgAccount = mdiAccount
  svgAt = mdiAt
  svgChairRolling = mdiChairRolling
  svgBookAlertOutline = mdiBookAlertOutline
  svgMenuDown = mdiMenuDown

  selectedShop: any = null

  headers = [
    { text: 'Domain', value: 'domain', sortable: true },
    { text: 'Status', value: 'status', sortable: true },
    { text: 'Contacts', value: 'contacts', sortable: false },
    { text: 'Tags', value: 'tags', sortable: false },
    { text: 'Page Rank', value: 'pageRank', sortable: false },
    { text: 'Sales Revenue $', value: 'salesRevenue', sortable: true },
    { text: '7Days Orders', value: 'latestSessionOrders', sortable: true },
    { text: 'Total Orders', value: 'orders', sortable: true },
    { text: 'Comment', value: 'comment', sortable: false }
  ]

  contactsHeaders = [
    { text: 'Name', value: 'name', sortable: false },
    { text: 'Title', value: 'title', sortable: false },
    { text: 'Email', value: 'email', sortable: false }
  ]

  possibleStatuses = ['not-scraped', 'inactive', 'collecting data', 'collected', 'monitoring']

  debouncedGetShops = debounce(this.getShops, 1000)

  get shops(): any[] {
    return this.$store.state.shops
  }

  get allTags(): any[] {
    return this.$store.state.tags
  }

  get filteredTags() {
    if (!this.searchTags) return this.allTags

    return this.allTags.filter(t => t.text.toLowerCase().startsWith(this.searchTags.toLowerCase()))
  }

  get params(): any {
    return this.$store.state.params
  }

  async updateItemsPerPage(itemsPerPage: number): Promise<void> {
    await this.updateParams({ ...this.params, itemsPerPage, page: 1 })
  }

  async updateSearchTags(searchTags: string): Promise<void> {
    await this.updateParams({ ...this.params, searchTags, page: 1 })
  }

  async updatePage(page: number): Promise<void> {
    await this.updateParams({ ...this.params, page })
  }

  async updateDomain(domain: string): Promise<void> {
    await this.updateParams({ ...this.params, domain, page: 1 })
  }

  async updateAdvancedFilters(): Promise<void> {
    await this.updateParams({ ...this.params, advancedFilters: this.advancedFilters, page: 1 })
  }

  async updateStoreType(authenticated: 0 | 1) {
    await this.updateParams({ ...this.params, authenticated })
  }

  async updateSortDesc(sortDesc) {
    await this.updateParams({ ...this.params, sortDesc: sortDesc[0] })
  }

  async updateSortBy(sortBy: string) {
    await this.updateParams({ ...this.params, sortBy: sortBy[0] })
  }

  async updateStatus() {
    await this.updateParams({ ...this.params, status: this.status, page: 1 })
  }

  async updateParams(newParams: any): Promise<void> {
    this.$store.dispatch('setParams', newParams)
    await this.debouncedGetShops()
  }

  async toggleFollow(shop: any) {
    const followed = !shop.followed
    this.loading = true

    try {
      await this.$store.dispatch('putShop', { _id: shop._id, followed })
      this.snackbarSuccess = true
      this.commentDialog = false
      await this.getShops()
    } catch (error) {
      this.snackbarError = true
      console.log('error', error)
    }

    this.loading = false
  }

  async created(): Promise<void> {
    this.domain = this.params.Domain
    this.status = this.params.status
    this.searchTags = this.params.searchTags
    await this.debouncedGetShops()
  }

  async getShops(): Promise<void> {
    this.loading = true

    try {
      await this.$store.dispatch('getShops', this.params)
      await this.$store.dispatch('getTags')
    } catch (error) {
      this.snackbarError = true
      console.log('error', error)
    }
    this.loading = false
  }

  // @Watch('selectedShop.tags', { deep: true })
  async updateShop(): Promise<void> {
    try {
      if (!this.selectedShop) return
      await this.$store.dispatch('putShop', this.selectedShop)
      this.snackbarSuccess = true
      this.commentDialog = false
    } catch (error) {
      this.snackbarError = true
      console.log('error', error)
    }
  }

  async postTag(): Promise<void> {
    try {
      if (this.newTagText) {
        const tag = await this.$store.dispatch('postTag', { text: this.newTagText })
        this.selectedShop.tags.push(tag)
      }

      await this.$store.dispatch('putShop', this.selectedShop)

      this.newTagText = ''
      this.snackbarSuccess = true
      this.tagsDialog = false
    } catch (error) {
      this.snackbarError = true
      console.log('error', error)
    }
  }

  async removeTag(tagId: string): Promise<void> {
    try {
      this.selectedShop.tags = this.selectedShop.tags.filter(t => t._id !== tagId)
      await this.$store.dispatch('putShop', this.selectedShop)
      this.snackbarSuccess = true
    } catch (error) {
      this.snackbarError = true
      console.log('error', error)
    }
  }

  // @Watch('searchTags')
  // async getExistingTags() {
  //   this.loading = true
  //   await this.$store.dispatch('getTags', this.searchTags)
  //   this.loading = false
  // }
  showContactsDialog(shop: any) {
    this.selectedShop = shop
    this.contactsDialog = true
  }

  showCommentDialog(shop: any) {
    this.selectedShop = shop
    this.commentDialog = true
  }

  showTagsDialog(shop: any) {
    this.selectedShop = shop
    this.tagsDialog = true
  }
}
