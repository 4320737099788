
import { Component, Vue, Prop } from 'vue-property-decorator'
import { mdiAccount, mdiAt, mdiChairRolling } from '@mdi/js'

@Component
export default class Contact extends Vue {
  @Prop({ required: true, type: Object }) contact!: yakkyo.IShopMonitorContact

  svgAccount = mdiAccount
  svgChairRolling = mdiChairRolling
  svgAt = mdiAt
}
